import { createContext, useContext, useState, useEffect } from 'react';
import { 
  User, 
  GoogleAuthProvider, 
  signInWithPopup, 
  RecaptchaVerifier, 
  signInWithPhoneNumber,
  ApplicationVerifier
} from 'firebase/auth';
import { auth } from '../lib/firebase';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  error: string | null;
  signInWithGoogle: () => Promise<void>;
  signInWithPhone: (phoneNumber: string) => Promise<any>;
  verifyCode: (code: string) => Promise<void>;
  logout: () => Promise<void>;
  clearError: () => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [confirmationResult, setConfirmationResult] = useState<any>(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<ApplicationVerifier | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const signInWithGoogle = async () => {
    try {
      setError(null);
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
    } catch (error: any) {
      console.error('Error signing in with Google:', error);
      setError(error.message || 'Erreur lors de la connexion avec Google');
    }
  };

  const signInWithPhone = async (phoneNumber: string) => {
    try {
      setError(null);
      
      // Clean up any existing reCAPTCHA
      if (recaptchaVerifier) {
        (recaptchaVerifier as any).clear();
      }

      // Create new reCAPTCHA verifier
      const verifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'normal',
        callback: () => {
          // reCAPTCHA solved
        },
        'expired-callback': () => {
          setError('Le reCAPTCHA a expiré. Veuillez réessayer.');
        }
      });

      setRecaptchaVerifier(verifier);

      // Format phone number if needed
      const formattedNumber = phoneNumber.startsWith('+') 
        ? phoneNumber 
        : `+33${phoneNumber.replace(/^0/, '')}`;

      const confirmation = await signInWithPhoneNumber(auth, formattedNumber, verifier);
      setConfirmationResult(confirmation);
      return confirmation;
    } catch (error: any) {
      console.error('Error signing in with phone:', error);
      setError(error.message || 'Erreur lors de l\'envoi du code de vérification');
      throw error;
    }
  };

  const verifyCode = async (code: string) => {
    try {
      setError(null);
      if (!confirmationResult) {
        throw new Error('Veuillez d\'abord demander un code de vérification');
      }
      await confirmationResult.confirm(code);
    } catch (error: any) {
      console.error('Error verifying code:', error);
      setError(error.message || 'Code de vérification incorrect');
      throw error;
    }
  };

  const logout = async () => {
    try {
      setError(null);
      await auth.signOut();
    } catch (error: any) {
      console.error('Error signing out:', error);
      setError(error.message || 'Erreur lors de la déconnexion');
    }
  };

  const clearError = () => setError(null);

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        loading, 
        error, 
        signInWithGoogle,
        signInWithPhone,
        verifyCode,
        logout, 
        clearError 
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within AuthProvider');
  return context;
};