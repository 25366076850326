import { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../components/AuthProvider';
import { Dialog } from '@headlessui/react';
import { MapPin, Phone, Star, Plus, Pencil, Trash2, X, Share2, User, Minus, RotateCcw, Edit, Trash, ChevronRight, ChevronLeft } from 'lucide-react';
import { useGoogleMaps } from '../hooks/useGoogleMaps';
import StarRating from '../components/StarRating';
import ShareModal from '../components/ShareModal';
import {
  addUserPlace,
  addItemToPlace,
  updateItem,
  deleteItem,
  getPlaceItems,
  uploadItemPhoto,
  deletePlace,
  getSharedWithMe,
  deleteCustomPlace,
  updateCustomPlace,
  uploadPlacePhoto,
} from '../lib/firebase';
import { useAnalytics } from '../hooks/useAnalytics';

const DELICE_CATEGORIES = [
  { value: 'entree', label: 'Entrée' },
  { value: 'plat', label: 'Plat' },
  { value: 'dessert', label: 'Dessert' },
  { value: 'boisson', label: 'Boisson' },
  { value: 'snack', label: 'Snack' },
  { value: 'autre', label: 'Autre' }
] as const;

interface Item {
  id: string;
  name: string;
  rating: number;
  comment?: string;
  photoUrl?: string;
  category?: string;
  createdAt: any;
  ownerEmail?: string;
  ownerName?: string;
}

interface ItemForm {
  name: string;
  rating: number;
  comment: string;
  photo: File | null;
  category: string;
}

export default function Place() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { getPlaceDetails } = useGoogleMaps();
  const analytics = useAnalytics();
  const [place, setPlace] = useState<any>(null);
  const [myItems, setMyItems] = useState<Item[]>([]);
  const [sharedItems, setSharedItems] = useState<Item[]>([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [editingItem, setEditingItem] = useState<Item | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [itemForm, setItemForm] = useState<ItemForm>({
    name: '',
    rating: 0,
    comment: '',
    photo: null,
    category: ''
  });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<Item | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [imageScale, setImageScale] = useState(1);
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [photoPreview, setPhotoPreview] = useState<string | null>(null);
  const [showEditPlaceModal, setShowEditPlaceModal] = useState(false);
  const [editPlaceForm, setEditPlaceForm] = useState({
    name: '',
    address: '',
    city: '',
    phone: '',
    photos: [] as string[]
  });  
  const [editingPlace, setEditingPlace] = useState(false);
  const [showDeletePlaceConfirm, setShowDeletePlaceConfirm] = useState(false);
  const [searchParams] = useSearchParams();
  const sharedByUserId = searchParams.get('sharedByUserId');

  interface EditPlaceForm {
    name: string;
    address: string;
    city: string;
    phone: string;
    photos: string[];
  }  

  useEffect(() => {
    const loadPlace = async () => {
      if (!id || !user) return;

      try {
        setLoading(true);
        const placeDetails = await getPlaceDetails(id,sharedByUserId);
        setPlace(placeDetails);

        // Charger mes délices
        const myPlaceItems = await getPlaceItems(user.uid, id);
        setMyItems(myPlaceItems);

        // Charger les délices partagés avec moi
        const sharedPlaces = await getSharedWithMe(user.email || '');
        const sharedPlace = sharedPlaces.find(p => p.id === id);
        if (sharedPlace) {
          const sharedItems = sharedPlace.items.map(item => ({
            ...item,
            ownerEmail: sharedPlace.sharedBy,
            ownerName: sharedPlace.ownerName
          }));
          setSharedItems(sharedItems);
        }

        // Ajouter le lieu à mes lieux si j'ai des délices
        if (myPlaceItems.length > 0) {
          await addUserPlace(user.uid, id, {
            name: placeDetails.name,
            address: placeDetails.formatted_address || placeDetails.vicinity,
            location: {
              lat: placeDetails.geometry.location.lat(),
              lng: placeDetails.geometry.location.lng()
            },
            phone: placeDetails.formatted_phone_number,
            types: placeDetails.types,
            photoUrl: placeDetails.photos?.[0]?.getUrl()
          }, true);
        }
      } catch (error) {
        console.error('Error loading place:', error);
        setError('Erreur lors du chargement du lieu');
      } finally {
        setLoading(false);
      }
    };

    loadPlace();
  }, [id, user, sharedByUserId, getPlaceDetails]);

  const resetForm = () => {
    setItemForm({
      name: '',
      rating: 0,
      comment: '',
      photo: null,
      category: ''
    });
    setError(null);
  };

  const handleCloseModal = () => {
    if (photoPreview) {
      URL.revokeObjectURL(photoPreview);
    }
    setShowModal(false);
    setEditingItem(null);
    setPhotoPreview(null);
    resetForm();
  };  

  const handleEditClick = (item: Item) => {
    setEditingItem(item);
    setItemForm({
      name: item.name,
      rating: item.rating,
      comment: item.comment || '',
      photo: null,
      category: item.category || 'autre'
    });
    setShowModal(true);
  };

  const handleDeleteClick = (item: Item) => {
    setItemToDelete(item);
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = async () => {
    if (!user || !id || !itemToDelete) return;

    try {
      await deleteItem(user.uid, id, itemToDelete.id);
      analytics.trackItemAction('delete');
      const updatedItems = myItems.filter(item => item.id !== itemToDelete.id);
      setMyItems(updatedItems);
      
      if (updatedItems.length === 0 && sharedItems.length === 0) {
        await deletePlace(user.uid, id);
        navigate('/');
      }
      
      setShowDeleteConfirm(false);
      setItemToDelete(null);
    } catch (error) {
      console.error('Error deleting item:', error);
      setError('Erreur lors de la suppression');
    }
  };

  const handleEditPlace = () => {
    const addressParts = place.formatted_address?.split(',') || ['', ''];
    const city = addressParts[addressParts.length - 1]?.trim() || '';
    const address = addressParts.slice(0, -1).join(',').trim();
  
    setEditPlaceForm({
      name: place.name,
      address: address,
      city: city,
      phone: place.formatted_phone_number || '',
      photos: place.photos?.map(photo => typeof photo === 'string' ? photo : photo.getUrl()) || []
    });
    setShowEditPlaceModal(true);
  };  
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !id) return;

    // Validation des champs obligatoires
    if (!itemForm.name.trim()) {
      setError('Le nom est obligatoire');
      return;
    }
    if (!itemForm.category) {
      setError('La catégorie est obligatoire');
      return;
    }
    if (itemForm.rating === 0) {
      setError('La note est obligatoire');
      return;
    }

    try {
      setError(null);

      if (!editingItem && myItems.some(item => item.name.toLowerCase() === itemForm.name.toLowerCase())) {
        setError('Un délice avec ce nom existe déjà dans ce lieu');
        return;
      }

      let photoUrl = editingItem?.photoUrl;
      if (itemForm.photo) {
        photoUrl = await uploadItemPhoto(user.uid, id, itemForm.photo);
        analytics.trackPhotoUpload();
      } else if (editingItem && !editingItem.photoUrl) {
        // Si on édite et qu'on a supprimé la photo
        photoUrl = null;
      }


      const itemData = {
        name: itemForm.name,
        rating: itemForm.rating,
        category: itemForm.category,
        ...(itemForm.comment && { comment: itemForm.comment }),
        ...(photoUrl && { photoUrl })
      };

      if (editingItem) {
        await updateItem(user.uid, id, editingItem.id, itemData);
        analytics.trackItemAction('edit');
        setMyItems(myItems.map(item => 
          item.id === editingItem.id ? { 
            ...item, 
            ...itemData,
            photoUrl: photoUrl // Ajout explicite de photoUrl qui peut être null
          } : item
        ));
      } else {
        if (myItems.length === 0) {
          await addUserPlace(user.uid, id, {
            name: place.name,
            address: place.formatted_address || place.vicinity,
            location: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng()
            },
            phone: place.formatted_phone_number,
            types: place.types,
            photoUrl: place.photos?.[0]?.getUrl()
          }, true);
        }

        const itemRef = await addItemToPlace(user.uid, id, itemData);
        analytics.trackItemAction('add');
        setMyItems([...myItems, { id: itemRef.id, ...itemData, createdAt: new Date() }]);
      }

      handleCloseModal();
    } catch (error) {
      console.error('Error saving item:', error);
      setError('Erreur lors de la sauvegarde');
    }
  };

  const renderItemsSection = (items: Item[], title: string, isShared: boolean = false) => {
    if (items.length === 0) return null;

    return (
      <div className="space-y-4">
        <h2 className="text-xl font-bold text-gold-300">{title}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {items.map((item) => (
            <div key={item.id} className="bg-dark-800 rounded-xl shadow-xl overflow-hidden border border-dark-700">
              {item.photoUrl ? (
                <div 
                  className="h-48 overflow-hidden cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedImage(item.photoUrl);
                  }}
                >
                  <img
                    src={item.photoUrl}
                    alt={item.name}
                    className="w-full h-full object-cover hover:opacity-90 transition-opacity"
                  />
                </div>
              ) : (
                <div className="h-48 bg-dark-700 flex items-center justify-center">
                  <span className="text-gray-500">Pas de photo</span>
                </div>
              )}
              <div className="p-4">
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-semibold text-lg text-gold-300">{item.name}</h3>
                    <p className="text-sm text-gray-400 mt-1">
                      {DELICE_CATEGORIES.find(cat => cat.value === item.category)?.label || 'Autre'}
                    </p>
                    {isShared && item.ownerEmail && (
                      <div className="flex items-center gap-2 mt-1 text-sm text-gray-400">
                        <User className="w-4 h-4" />
                        <span>{item.ownerEmail}</span>
                      </div>
                    )}
                    <StarRating rating={item.rating} readonly />
                  </div>
                  {!isShared && (
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleEditClick(item)}
                        className="p-1 text-gray-400 hover:text-gold-400 transition-colors"
                      >
                        <Pencil className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleDeleteClick(item)}
                        className="p-1 text-gray-400 hover:text-red-500 transition-colors"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </div>
                  )}
                </div>
                {item.comment && (
                  <p className="mt-2 text-gray-400">{item.comment}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gold-400"></div>
      </div>
    );
  }

  if (!place) {
    return (
      <div className="text-center py-12">
        <h2 className="text-xl font-semibold text-gray-300">Lieu non trouvé</h2>
        <button
          onClick={() => navigate(-1)}
          className="mt-4 text-gold-400 hover:text-gold-300"
        >
          Retour
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="bg-dark-800 shadow-xl rounded-xl overflow-hidden border border-dark-700">
        {/* Header avec galerie photos */}
          <div className="relative h-48">
            {place.photos && place.photos.length > 0 ? (
              <div className="flex overflow-x-auto hide-scrollbar h-full">
                {place.photos.map((photo, index) => (
                  <img
                    key={index}
                    src={typeof photo === 'string' ? photo : photo.getUrl()}
                    alt={`${place.name} - Photo ${index + 1}`}
                    className="h-full w-auto object-cover cursor-pointer"
                    onClick={() => setSelectedImage(typeof photo === 'string' ? photo : photo.getUrl())}
                  />
                ))}
              </div>
            ) : (
              <div className="h-full bg-dark-700 flex items-center justify-center">
                <span className="text-gray-500">Pas de photo</span>
              </div>
            )}
            {place.photos && place.photos.length > 1 && (
              <>
                <div className="absolute left-0 right-0 top-1/2 -translate-y-1/2 flex justify-between px-4 pointer-events-none">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      const container = document.querySelector('.overflow-x-auto');
                      if (container && container.scrollLeft > 0) {
                        container.scrollBy({ left: -300, behavior: 'smooth' });
                      }
                    }}
                    className="pointer-events-auto bg-black/50 hover:bg-black/70 text-white rounded-full p-2 transition-opacity"
                    style={{ opacity: document.querySelector('.overflow-x-auto')?.scrollLeft > 0 ? 1 : 0 }}
                  >
                    <ChevronLeft className="w-6 h-6" />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      const container = document.querySelector('.overflow-x-auto');
                      if (container) {
                        container.scrollBy({ left: 300, behavior: 'smooth' });
                      }
                    }}
                    className="pointer-events-auto bg-black/50 hover:bg-black/70 text-white rounded-full p-2"
                  >
                    <ChevronRight className="w-6 h-6" />
                  </button>
                </div>
              </>
            )}
          </div>

        {/* Place Details */}
        <div className="p-6 flex flex-col space-y-4">
          {/* Titre */}
          <h1 className="text-2xl font-bold text-gold-300">{place.name}</h1>
          
          {/* Informations */}
          <div className="flex flex-col space-y-3">
            <div className="flex items-start text-gray-400">
              <MapPin className="w-4 h-4 mr-2 text-gold-400 flex-shrink-0 mt-1" />
              <p className="break-words">{place.formatted_address || place.vicinity}</p>
            </div>
            
            {place.formatted_phone_number && (
              <div className="flex items-center text-gray-400">
                <Phone className="w-4 h-4 mr-2 text-gold-400 flex-shrink-0" />
                <p>{place.formatted_phone_number}</p>
              </div>
            )}
            
            {place.rating && (
              <div className="flex items-center">
                <Star className="w-4 h-4 text-gold-400 fill-current mr-1 flex-shrink-0" />
                <span className="text-white">{place.rating}</span>
                <span className="text-gray-400 ml-1">
                  ({place.user_ratings_total} avis Google)
                </span>
              </div>
            )}
          </div>

          {/* Boutons d'action */}
          <div className="flex flex-col sm:flex-row gap-2 pt-2">
            <div className="flex-1 flex gap-2">
              <button
                onClick={() => setShowShareModal(true)}
                className="flex-1 sm:flex-none flex items-center justify-center px-4 py-2 bg-dark-700 text-gold-400 font-semibold rounded-lg hover:bg-dark-600 transition-colors"
              >
                <Share2 className="w-5 h-5 mr-2" />
                Partager
              </button>
              <button
                onClick={() => setShowModal(true)}
                className="flex-1 sm:flex-none flex items-center justify-center px-4 py-2 bg-gold-500 text-dark-900 font-semibold rounded-lg hover:bg-gold-600 transition-colors"
              >
                <Plus className="w-5 h-5 mr-2" />
                Ajouter un délice
              </button>
            </div>

            {/* Boutons pour les lieux customs */}
            {place.types?.includes('custom') && (
              <div className="flex gap-2 flex-1 sm:flex-none">
                {!sharedByUserId && (
                <button
                  onClick={handleEditPlace}
                  className="flex-1 sm:flex-none flex items-center justify-center px-4 py-2 bg-dark-700 text-gold-400 font-semibold rounded-lg hover:bg-dark-600 transition-colors"
                >
                  <Edit className="w-5 h-5 mr-2" />
                  Modifier
                </button>
                )}
                {myItems.length === 0 && !sharedByUserId && (
                  <button
                    onClick={() => setShowDeletePlaceConfirm(true)}
                    className="flex-1 sm:flex-none flex items-center justify-center px-4 py-2 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-600 transition-colors"
                  >
                    <Trash className="w-5 h-5 mr-2" />
                    Supprimer
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* My Items Section */}
      {renderItemsSection(myItems, 'Mes délices')}

      {/* Shared Items Section */}
      {renderItemsSection(sharedItems, 'Délices partagés avec moi', true)}

      {/* Add/Edit Item Modal */}
      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <div className="relative bg-dark-800 rounded-xl max-w-md w-full mx-4 p-6 border border-dark-700">
            <div className="absolute top-4 right-4">
              <button
                onClick={handleCloseModal}
                className="text-gray-400 hover:text-gray-300"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            <Dialog.Title className="text-lg font-medium text-gold-300 mb-4">
              {editingItem ? 'Modifier le délice' : 'Ajouter un délice'}
            </Dialog.Title>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Nom *
                </label>
                <input
                  type="text"
                  value={itemForm.name}
                  onChange={(e) => setItemForm({ ...itemForm, name: e.target.value })}
                  className="mt-1 block w-full rounded-md bg-dark-700 border-dark-600 text-white focus:border-gold-500 focus:ring-gold-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Catégorie *
                </label>
                <select
                  value={itemForm.category}
                  onChange={(e) => setItemForm({ ...itemForm, category: e.target.value })}
                  className="mt-1 block w-full rounded-md bg-dark-700 border-dark-600 text-white focus:border-gold-500 focus:ring-gold-500"
                  required
                >
                  <option value="">Sélectionner une catégorie</option>
                  {DELICE_CATEGORIES.map(category => (
                    <option key={category.value} value={category.value}>
                      {category.label}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Note *
                </label>
                <StarRating
                  rating={itemForm.rating}
                  onChange={(rating) => setItemForm({ ...itemForm, rating })}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Commentaire (facultatif)
                </label>
                <textarea
                  value={itemForm.comment}
                  onChange={(e) => setItemForm({ ...itemForm, comment: e.target.value })}
                  className="mt-1 block w-full rounded-md bg-dark-700 border-dark-600 text-white focus:border-gold-500 focus:ring-gold-500"
                  rows={3}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Photo (facultatif)
                </label>
                {(photoPreview || (editingItem?.photoUrl && !itemForm.photo)) ? (
                  <div className="mt-1 relative">
                    <img 
                      src={photoPreview || editingItem?.photoUrl} 
                      alt="Prévisualisation" 
                      className="h-48 w-full object-cover rounded-lg"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        if (window.confirm('Êtes-vous sûr de vouloir supprimer cette photo ?')) {
                          if (photoPreview) {
                            URL.revokeObjectURL(photoPreview);
                            setPhotoPreview(null);
                          }
                          setItemForm({ ...itemForm, photo: null });
                          if (editingItem) {
                            // Si on édite, on met photoUrl à null dans le formulaire
                            setEditingItem({ ...editingItem, photoUrl: null });
                          }
                        }
                      }}
                      className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                    >
                      <Trash2 className="w-5 h-5" />
                    </button>
                  </div>
                ) : (
                  <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dark-600 border-dashed rounded-md hover:border-dark-500">
                    <div className="space-y-1 text-center">
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="flex text-sm text-gray-400">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer bg-dark-800 rounded-md font-medium text-gold-400 hover:text-gold-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-gold-500"
                        >
                          <span>Télécharger un fichier</span>
                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            accept="image/*"
                            className="sr-only"
                            onChange={(e) => {
                              const file = e.target.files?.[0];
                              if (file) {
                                setItemForm({ ...itemForm, photo: file });
                                const previewUrl = URL.createObjectURL(file);
                                setPhotoPreview(previewUrl);
                              }
                            }}
                          />
                        </label>
                      </div>
                      <p className="text-xs text-gray-500">
                        PNG, JPG jusqu'à 10MB
                      </p>
                    </div>
                  </div>
                )}
              </div>


              {error && (
                <p className="text-sm text-red-500">{error}</p>
              )}

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="px-4 py-2 text-sm font-medium text-gray-300 bg-dark-700 border border-dark-600 rounded-md hover:bg-dark-600"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-dark-900 bg-gold-500 border border-transparent rounded-md hover:bg-gold-600"
                >
                  {editingItem ? 'Modifier' : 'Ajouter'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Dialog>

      {/* Share Modal */}
      <ShareModal
        isOpen={showShareModal}
        onClose={() => setShowShareModal(false)}
        placeId={id}
        placeName={place.name}
      />

      {/* Delete Confirmation Modal */}
      <Dialog
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <div className="relative bg-dark-800 rounded-xl max-w-md w-full mx-4 p-6 border border-dark-700">
            <Dialog.Title className="text-lg font-medium text-gold-300 mb-4">
              Confirmer la suppression
            </Dialog.Title>

            <p className="text-sm text-gray-400">
              Êtes-vous sûr de vouloir supprimer ce délice ? Cette action est irréversible.
            </p>

            <div className="mt-4 flex justify-end space-x-3">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="px-4 py-2 text-sm font-medium text-gray-300 bg-dark-700 border border-dark-600 rounded-md hover:bg-dark-600"
              >
                Annuler
              </button>
              <button
                onClick={handleConfirmDelete}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700"
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Image Viewer Modal */}
      <Dialog
        open={!!selectedImage}
        onClose={() => {
          setSelectedImage(null);
          setImageScale(1);
        }}
        className="fixed inset-0 z-50 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-90" />

          <div className="relative">
            {/* Close button */}
            <button
              onClick={() => {
                setSelectedImage(null);
                setImageScale(1);
                setImagePosition({ x: 0, y: 0 });
              }}
              className="absolute top-4 right-4 text-white hover:text-gray-300 z-50"
            >
              <X className="w-6 h-6" />
            </button>

            {/* Zoom controls */}
            <div className="absolute bottom-4 right-4 flex gap-2 z-50">
              <button
                onClick={() => setImageScale(scale => Math.max(0.5, scale - 0.5))}
                className="p-2 bg-dark-800 rounded-full text-white hover:bg-dark-700"
              >
                <Minus className="w-5 h-5" />
              </button>
              <button
                onClick={() => setImageScale(1)}
                className="p-2 bg-dark-800 rounded-full text-white hover:bg-dark-700"
              >
                <RotateCcw className="w-5 h-5" />
              </button>
              <button
                onClick={() => setImageScale(scale => Math.min(3, scale + 0.5))}
                className="p-2 bg-dark-800 rounded-full text-white hover:bg-dark-700"
              >
                <Plus className="w-5 h-5" />
              </button>
            </div>

            {/* Image */}
            {selectedImage && (
              <div className="touch-none">
                <img
                  src={selectedImage}
                  alt="Vue agrandie"
                  className="max-h-[90vh] transition-transform duration-200 touch-none"
                  style={{ 
                    transform: `scale(${imageScale}) translate(${imagePosition.x}px, ${imagePosition.y}px)`,
                    cursor: 'default',
                    touchAction: 'none'
                  }}
                  onTouchStart={(e) => {
                    if (e.touches.length === 2) {
                      const touch1 = e.touches[0];
                      const touch2 = e.touches[1];
                      const distance = Math.hypot(
                        touch1.clientX - touch2.clientX,
                        touch1.clientY - touch2.clientY
                      );
                      e.currentTarget.dataset.initialPinchDistance = distance.toString();
                      e.currentTarget.dataset.initialScale = imageScale.toString();
                    }
                  }}
                  onTouchMove={(e) => {
                    if (e.touches.length === 2) {
                      // Gestion du pinch-zoom
                      const touch1 = e.touches[0];
                      const touch2 = e.touches[1];
                      const distance = Math.hypot(
                        touch1.clientX - touch2.clientX,
                        touch1.clientY - touch2.clientY
                      );
                      const initialDistance = parseFloat(e.currentTarget.dataset.initialPinchDistance || '0');
                      const initialScale = parseFloat(e.currentTarget.dataset.initialScale || '1');
                      const newScale = (distance / initialDistance) * initialScale;
                      setImageScale(Math.min(5, Math.max(1, newScale)));
                    } else if (e.touches.length === 1 && imageScale > 1) {
                      // Gestion du déplacement
                      const touch = e.touches[0];
                      const movementX = touch.clientX - (e.currentTarget.dataset.lastX ? parseFloat(e.currentTarget.dataset.lastX) : touch.clientX);
                      const movementY = touch.clientY - (e.currentTarget.dataset.lastY ? parseFloat(e.currentTarget.dataset.lastY) : touch.clientY);
                      
                      e.currentTarget.dataset.lastX = touch.clientX.toString();
                      e.currentTarget.dataset.lastY = touch.clientY.toString();
                      
                      setImagePosition(prev => ({
                        x: prev.x + (movementX / imageScale),
                        y: prev.y + (movementY / imageScale)
                      }));
                    }
                  }}
                  onTouchEnd={(e) => {
                    if (e.touches.length < 2) {
                      delete e.currentTarget.dataset.initialPinchDistance;
                      delete e.currentTarget.dataset.initialScale;
                    }
                    if (e.touches.length === 0) {
                      delete e.currentTarget.dataset.lastX;
                      delete e.currentTarget.dataset.lastY;
                    }
                  }}
                />
              </div>
            )}
            {/* Navigation buttons */}
            <div className="absolute left-0 right-0 top-1/2 -translate-y-1/2 flex justify-between px-4">
              <div> {/* Conteneur gauche */}
                {place.photos && place.photos.findIndex(
                  photo => (typeof photo === 'string' ? photo : photo.getUrl()) === selectedImage
                ) > 0 && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      const currentIndex = place.photos.findIndex(
                        photo => (typeof photo === 'string' ? photo : photo.getUrl()) === selectedImage
                      );
                      const prevPhoto = place.photos[currentIndex - 1];
                      setSelectedImage(typeof prevPhoto === 'string' ? prevPhoto : prevPhoto.getUrl());
                      setImageScale(1);
                      setImagePosition({ x: 0, y: 0 });
                    }}
                    className="bg-black/50 hover:bg-black/70 text-white rounded-full p-2 transition-opacity"
                  >
                    <ChevronLeft className="w-6 h-6" />
                  </button>
                )}
              </div>
              <div> {/* Conteneur droit */}
                {place.photos && place.photos.findIndex(
                  photo => (typeof photo === 'string' ? photo : photo.getUrl()) === selectedImage
                ) < place.photos.length - 1 && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      const currentIndex = place.photos.findIndex(
                        photo => (typeof photo === 'string' ? photo : photo.getUrl()) === selectedImage
                      );
                      const nextPhoto = place.photos[currentIndex + 1];
                      setSelectedImage(typeof nextPhoto === 'string' ? nextPhoto : nextPhoto.getUrl());
                      setImageScale(1);
                      setImagePosition({ x: 0, y: 0 });
                    }}
                    className="bg-black/50 hover:bg-black/70 text-white rounded-full p-2 transition-opacity"
                  >
                    <ChevronRight className="w-6 h-6" />
                  </button>
                )}
              </div>
            </div>

          </div>
        </div>
      </Dialog>

      {/* Edit Place Modal */}
      <Dialog
        open={showEditPlaceModal}
        onClose={() => setShowEditPlaceModal(false)}
        className="fixed inset-0 z-20 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          
          <div className="relative bg-dark-800 w-full sm:max-w-lg mx-auto rounded-xl p-6">
            <h3 className="text-lg font-medium text-gold-300 mb-4">Modifier le lieu</h3>
            
            <form onSubmit={async (e) => {
              e.preventDefault();
              if (!user || !id) return;

              try {
                setEditingPlace(true);
                const updatedPlace = await updateCustomPlace(user.uid, id, editPlaceForm);
                
                // Mettre à jour l'affichage sans recharger la page
                setPlace(prev => ({
                  ...prev,
                  name: updatedPlace.name,
                  formatted_address: updatedPlace.address,
                  formatted_phone_number: updatedPlace.phone,
                  photos: updatedPlace.photos.map(url => ({
                    getUrl: () => url
                  })),
                  geometry: {
                    location: new google.maps.LatLng(
                      updatedPlace.location.lat,
                      updatedPlace.location.lng
                    )
                  }
                }));
                
                setShowEditPlaceModal(false);
              } catch (error: any) {
                setError(error.message);
              } finally {
                setEditingPlace(false);
              }
            }} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Nom *
                </label>
                <input
                  type="text"
                  required
                  value={editPlaceForm.name}
                  onChange={e => setEditPlaceForm(prev => ({ ...prev, name: e.target.value }))}
                  className="w-full px-4 py-2 bg-dark-700 border border-dark-600 rounded-lg text-white"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Adresse
                </label>
                <input
                  type="text"
                  value={editPlaceForm.address}
                  onChange={e => setEditPlaceForm(prev => ({ ...prev, address: e.target.value }))}
                  className="w-full px-4 py-2 bg-dark-700 border border-dark-600 rounded-lg text-white"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Ville *
                </label>
                <input
                  type="text"
                  required
                  value={editPlaceForm.city}
                  onChange={e => setEditPlaceForm(prev => ({ ...prev, city: e.target.value }))}
                  className="w-full px-4 py-2 bg-dark-700 border border-dark-600 rounded-lg text-white"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Téléphone
                </label>
                <input
                  type="tel"
                  value={editPlaceForm.phone}
                  onChange={e => setEditPlaceForm(prev => ({ ...prev, phone: e.target.value }))}
                  className="w-full px-4 py-2 bg-dark-700 border border-dark-600 rounded-lg text-white"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Photos
                </label>
                
                {/* Zone de téléchargement */}
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dark-600 border-dashed rounded-md hover:border-dark-500">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="flex text-sm text-gray-400">
                      <label
                        htmlFor="place-photo-upload"
                        className="relative cursor-pointer bg-dark-800 rounded-md font-medium text-gold-400 hover:text-gold-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-gold-500"
                      >
                        <span>Télécharger un fichier</span>
                        <input
                          id="place-photo-upload"
                          name="place-photo-upload"
                          type="file"
                          accept="image/*"
                          className="sr-only"
                          onChange={async (e) => {
                            const file = e.target.files?.[0];
                            if (file && user && id) {
                              try {
                                const photoUrl = await uploadPlacePhoto(user.uid, id, file);
                                setEditPlaceForm(prev => ({
                                  ...prev,
                                  photos: [...prev.photos, photoUrl]
                                }));
                              } catch (error) {
                                console.error('Error uploading photo:', error);
                                setError('Erreur lors du téléchargement de la photo');
                              }
                            }
                          }}
                        />
                      </label>
                    </div>
                    <p className="text-xs text-gray-500">
                      PNG, JPG jusqu'à 10MB
                    </p>
                  </div>
                </div>

                {/* Grille des photos existantes */}
                {editPlaceForm.photos.length > 0 && (
                  <div className="mt-4 grid grid-cols-2 gap-4">
                    {editPlaceForm.photos.map((photoUrl, index) => (
                      <div key={index} className="relative group">
                        <img
                          src={photoUrl}
                          alt={`Photo ${index + 1}`}
                          className="h-24 w-full object-cover rounded-lg"
                        />
                        <button
                          onClick={async () => {
                            if (!user || !id) return;
                            try {
                              await deletePlacePhoto(user.uid, id, photoUrl);
                              setEditPlaceForm(prev => ({
                                ...prev,
                                photos: prev.photos.filter(p => p !== photoUrl)
                              }));
                            } catch (error) {
                              console.error('Error deleting photo:', error);
                              setError('Erreur lors de la suppression de la photo');
                            }
                          }}
                          className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <Trash2 className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  onClick={() => setShowEditPlaceModal(false)}
                  className="px-4 py-2 text-sm text-gray-300 hover:text-white"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  disabled={editingPlace}
                  className="px-4 py-2 bg-gold-500 text-dark-900 rounded-lg disabled:opacity-50"
                >
                  {editingPlace ? 'Modification...' : 'Modifier'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Dialog>

      {/* Delete Place Confirmation Modal */}
      <Dialog
        open={showDeletePlaceConfirm}
        onClose={() => setShowDeletePlaceConfirm(false)}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <div className="relative bg-dark-800 rounded-xl max-w-md w-full mx-4 p-6 border border-dark-700">
            <Dialog.Title className="text-lg font-medium text-gold-300 mb-4">
              Confirmer la suppression
            </Dialog.Title>

            <p className="text-sm text-gray-400">
              Êtes-vous sûr de vouloir supprimer ce lieu ? Cette action est irréversible.
            </p>

            <div className="mt-4 flex justify-end space-x-3">
              <button
                onClick={() => setShowDeletePlaceConfirm(false)}
                className="px-4 py-2 text-sm font-medium text-gray-300 bg-dark-700 border border-dark-600 rounded-md hover:bg-dark-600"
              >
                Annuler
              </button>
              <button
                onClick={async () => {
                  if (!user || !id) return;
                  try {
                    await deleteCustomPlace(user.uid, id);
                    navigate('/search');
                  } catch (error: any) {
                    setError(error.message);
                  }
                }}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700"
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </Dialog>

    </div>
  );
}