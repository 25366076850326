import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    gtag: (
      command: 'event' | 'config' | 'js',
      action: string,
      params?: {
        [key: string]: any;
      }
    ) => void;
  }
}

export const useAnalytics = () => {
  const location = useLocation();

  // Suivi des pages vues
  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_path: location.pathname,
      page_title: document.title
    });
  }, [location]);

  // Événements personnalisés
  const trackEvent = (
    eventName: string,
    eventParams: { [key: string]: any } = {}
  ) => {
    window.gtag('event', eventName, {
      ...eventParams,
      timestamp: new Date().toISOString()
    });
  };

  // Actions spécifiques
  const trackSearch = () => {
    trackEvent('search');
  };

  const trackItemAction = (action: 'add' | 'edit' | 'delete') => {
    trackEvent('item_action', { action });
  };

  const trackPhotoUpload = () => {
    trackEvent('photo_upload');
  };

  return {
    trackEvent,
    trackSearch,
    trackItemAction,
    trackPhotoUpload
  };
};