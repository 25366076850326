import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin, Star, Calendar, Trash2 } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { getUserStats, getSharesByOwner, removeShare, removeAllShares } from '../lib/firebase';

interface SharedPlace {
  placeId: string;
  placeName: string;
  shares: Array<{
    id: string;
    toEmail: string;
    sharedAt: any;
  }>;
}

interface UserStats {
  totalPlaces: number;
  totalItems: number;
  totalPhotos: number;
  averageRating: string | number;
  totalRatings: number;
}

export default function Profile() {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<UserStats | null>(null);
  const [sharedPlaces, setSharedPlaces] = useState<SharedPlace[]>([]);

  useEffect(() => {
    const loadData = async () => {
      if (!user) return;
      try {
        setLoading(true);
        const [userStats, userShares] = await Promise.all([
          getUserStats(user.uid),
          getSharesByOwner(user.uid)
        ]);
        setStats(userStats);
        setSharedPlaces(userShares);
      } catch (error) {
        console.error('Error loading profile data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [user]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleRemoveShare = async (placeId: string, shareId: string) => {
    try {
      await removeShare(shareId);
      setSharedPlaces(sharedPlaces.map(place => {
        if (place.placeId === placeId) {
          return {
            ...place,
            shares: place.shares.filter(share => share.id !== shareId)
          };
        }
        return place;
      }));
    } catch (error) {
      console.error('Error removing share:', error);
    }
  };

  const handleRemoveAllShares = async () => {
    if (!user || !confirm('Êtes-vous sûr de vouloir supprimer tous les partages ?')) return;
    
    try {
      await removeAllShares(user.uid);
      setSharedPlaces([]);
    } catch (error) {
      console.error('Error removing all shares:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gold-400"></div>
      </div>
    );
  }

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      {/* User Info */}
      <div className="bg-dark-800 rounded-xl shadow-xl p-6 border border-dark-700">
        <div className="flex items-center space-x-4">
          {user.photoURL && (
            <img
              src={user.photoURL}
              alt={user.displayName || 'Profile'}
              className="w-16 h-16 rounded-full"
            />
          )}
          <div>
            <h1 className="text-2xl font-bold text-gold-300">
              {user.displayName || 'Utilisateur'}
            </h1>
            <p className="text-gray-400">{user.email}</p>
          </div>
        </div>
      </div>

      {/* Statistics */}
      {stats && (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-dark-800 rounded-xl shadow-xl p-6 border border-dark-700">
            <div className="flex items-center space-x-3">
              <MapPin className="w-8 h-8 text-gold-400" />
              <div>
                <p className="text-2xl font-bold text-gold-300">{stats.totalPlaces}</p>
                <p className="text-sm text-gray-400">Lieux visités</p>
              </div>
            </div>
          </div>

          <div className="bg-dark-800 rounded-xl shadow-xl p-6 border border-dark-700">
            <div className="flex items-center space-x-3">
              <Star className="w-8 h-8 text-gold-400" />
              <div>
                <p className="text-2xl font-bold text-gold-300">{stats.totalItems}</p>
                <p className="text-sm text-gray-400">Délices notés</p>
              </div>
            </div>
          </div>

          <div className="bg-dark-800 rounded-xl shadow-xl p-6 border border-dark-700">
            <div className="flex items-center space-x-3">
              <Calendar className="w-8 h-8 text-gold-400" />
              <div>
                <p className="text-2xl font-bold text-gold-300">{stats.averageRating}</p>
                <p className="text-sm text-gray-400">Note moyenne</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Shared Places Section */}
      {sharedPlaces.length > 0 && (
        <div className="bg-dark-800 rounded-xl shadow-xl p-6 border border-dark-700">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold text-gold-300">Mes lieux partagés</h2>
            <button
              onClick={handleRemoveAllShares}
              className="px-4 py-2 text-sm font-medium text-red-500 bg-dark-700 rounded-md hover:bg-dark-600 transition-colors"
            >
              Couper tous les partages
            </button>
          </div>
          
          <div className="space-y-4">
            {sharedPlaces.map((place) => (
              <div
                key={place.placeId}
                className="bg-dark-700 rounded-lg p-4"
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-semibold text-gold-300">{place.placeName}</h3>
                    <p className="text-sm text-gray-400 mt-1">
                      Partagé avec {place.shares.length} personne{place.shares.length > 1 ? 's' : ''}
                    </p>
                  </div>
                  <button
                    onClick={() => navigate(`/place/${place.placeId}`)}
                    className="px-3 py-1 text-sm text-gold-400 hover:text-gold-300"
                  >
                    Gérer
                  </button>
                </div>
                
                <div className="mt-3 space-y-2">
                  {place.shares.map((share) => (
                    <div
                      key={share.id}
                      className="flex items-center justify-between text-sm bg-dark-800 rounded-lg p-2"
                    >
                      <div>
                        <p className="text-gray-300">{share.toEmail}</p>
                        <p className="text-xs text-gray-500">
                          Partagé le {new Date(share.sharedAt.toDate()).toLocaleDateString()}
                        </p>
                      </div>
                      <button
                        onClick={() => handleRemoveShare(place.placeId, share.id)}
                        className="p-1 text-gray-400 hover:text-red-500 transition-colors"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Logout Button */}
      <button
        onClick={handleLogout}
        className="w-full bg-dark-800 text-red-500 hover:text-red-400 py-3 rounded-xl border border-dark-700 transition-colors"
      >
        Se déconnecter
      </button>
    </div>
  );
}