import { useState, useCallback } from 'react';
import { useGeolocation } from './useGeolocation';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../lib/firebase';

// Global cache for places
const placesCache = new Map<string, google.maps.places.PlaceResult>();

export function useGoogleMaps() {
  const { location } = useGeolocation();
  const [error, setError] = useState<string | null>(null);

  const searchNearbyPlaces = useCallback(async (
    type?: string,
    radius: number = 5000,
    keyword?: string
  ): Promise<google.maps.places.PlaceResult[]> => {
    if (!location || !window.google) {
      return [];
    }

    try {
      const service = new google.maps.places.PlacesService(
        document.createElement('div')
      );

      const searchLocation = new google.maps.LatLng(location.latitude, location.longitude);

      // Si on a un terme de recherche, utiliser textSearch pour chercher par nom
      if (keyword) {
        const request: google.maps.places.TextSearchRequest = {
          location: searchLocation,
          radius,
          query: keyword,
          type: type && type !== 'Tous' ? type as google.maps.places.PlaceType : undefined
        };

        const results = await new Promise<google.maps.places.PlaceResult[]>((resolve, reject) => {
          service.textSearch(request, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && results) {
              // Filtrer les résultats pour ne garder que ceux qui contiennent le mot-clé dans le nom
              const filteredResults = results.filter(place => 
                place.name?.toLowerCase().includes(keyword.toLowerCase())
              );
              resolve(filteredResults);
            } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
              resolve([]);
            } else {
              reject(new Error('Failed to fetch places'));
            }
          });
        });

        // Mettre en cache les résultats
        results.forEach(place => {
          if (place.place_id) {
            placesCache.set(place.place_id, place);
          }
        });

        return results;
      }

      // Sans terme de recherche, utiliser nearbySearch pour tous les lieux
      const request: google.maps.places.PlaceSearchRequest = {
        location: searchLocation,
        radius,
        type: type && type !== 'Tous' ? type as google.maps.places.PlaceType : undefined
      };

      const results = await new Promise<google.maps.places.PlaceResult[]>((resolve, reject) => {
        service.nearbySearch(request, (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && results) {
            resolve(results);
          } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
            resolve([]);
          } else {
            reject(new Error('Failed to fetch places'));
          }
        });
      });

      // Mettre en cache les résultats
      results.forEach(place => {
        if (place.place_id) {
          placesCache.set(place.place_id, place);
        }
      });

      return results;
    } catch (err) {
      console.error('Error fetching places:', err);
      setError('Une erreur est survenue lors de la recherche des lieux');
      return [];
    }
  }, [location]);

  const getPlaceDetails = useCallback(async (placeId: string, sharedByUserId: string | null = null): Promise<google.maps.places.PlaceResult> => {
    // Check cache first
    const cachedPlace = placesCache.get(placeId);
    if (cachedPlace) {
      return Promise.resolve(cachedPlace);
    }
  
    try {
      // Si c'est un lieu personnalisé
      if (placeId.startsWith('custom_')) {
        // Récupérer les données depuis Firestore
        const ownerId = sharedByUserId? sharedByUserId : auth.currentUser?.uid;
        const placeDoc = await getDoc(doc(db, 'users', ownerId || '', 'places', placeId));
        if (!placeDoc.exists()) {
          throw new Error('Lieu non trouvé');
        }
  
        const placeData = placeDoc.data();
        // Convertir les données Firestore en format compatible avec PlaceResult
        const customPlace: google.maps.places.PlaceResult = {
          place_id: placeId,
          name: placeData.name,
          formatted_address: placeData.address,
          formatted_phone_number: placeData.phone,
          geometry: {
            location: new google.maps.LatLng(placeData.location.lat, placeData.location.lng)
          },
          types: placeData.types || ['custom'],
          photos: placeData.photos ? placeData.photos.map((url: string) => ({
            getUrl: () => url
          })) : undefined
        };
  
        // Cache the result
        placesCache.set(placeId, customPlace);
        return customPlace;
      }
  
      // Pour les lieux Google Maps, utiliser l'API Places
      const service = new google.maps.places.PlacesService(
        document.createElement('div')
      );
  
      const place = await new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
        service.getDetails(
          {
            placeId,
            fields: [
              'name',
              'formatted_address',
              'formatted_phone_number',
              'geometry',
              'types',
              'photos',
              'rating',
              'user_ratings_total',
              'vicinity'
            ]
          },
          (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && place) {
              resolve(place);
            } else {
              reject(new Error('Failed to fetch place details'));
            }
          }
        );
      });
  
      // Cache the result
      placesCache.set(placeId, place);
      return place;
    } catch (error) {
      console.error('Error fetching place details:', error);
      throw error;
    }
  }, []);  
  
  return {
    searchNearbyPlaces,
    getPlaceDetails,
    location,
    error
  };
}