import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin, Navigation, Star, Clock, Phone } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import FranceMap from '../components/FranceMap';
import StarRating from '../components/StarRating';
import { getUserPlaces, getTopRatedPlaces, getRecentPlaces } from '../lib/firebase';

interface Place {
  id: string;
  name: string;
  address: string;
  location: { lat: number; lng: number };
  lastVisited: any;
  avgRating?: number;
  itemsCount?: number;
}

export default function Home() {
  const { user, signInWithGoogle, signInWithPhone, verifyCode } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [places, setPlaces] = useState<Place[]>([]);
  const [topRatedPlaces, setTopRatedPlaces] = useState<Place[]>([]);
  const [recentPlaces, setRecentPlaces] = useState<Place[]>([]);
  const [showPhoneAuth, setShowPhoneAuth] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadData = async () => {
      if (!user) return;

      try {
        setLoading(true);
        const [allPlaces, rated, recent] = await Promise.all([
          getUserPlaces(user.uid),
          getTopRatedPlaces(user.uid),
          getRecentPlaces(user.uid)
        ]);

        setPlaces(allPlaces);
        setTopRatedPlaces(rated);
        setRecentPlaces(recent);
      } catch (error) {
        console.error('Error loading home data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [user]);

  if (!user) {
    return (
      <div className="min-h-[80vh] flex flex-col items-center justify-center px-4">
        <img src="/official-logo.png" alt="TopDélice" className="w-24 h-24 mb-6" />
        <h1 className="text-4xl font-bold mb-4 text-gold-300">Bienvenue sur TopDélice</h1>
        <p className="text-gray-400 mb-8 text-center max-w-md">
          Découvrez et notez les meilleurs restaurants, cafés et bars autour de vous
        </p>
        
        <div className="flex flex-col gap-4 w-full max-w-sm">
          <button
            onClick={signInWithGoogle}
            className="flex items-center justify-center px-6 py-3 bg-dark-800 text-white rounded-lg border border-dark-700 hover:bg-dark-700 transition-colors"
          >
            <img 
              src="/google.svg" 
              alt="Google" 
              className="w-5 h-5 mr-3"
            />
            Continuer avec Google
          </button>

          <button
            onClick={() => setShowPhoneAuth(true)}
            className="flex items-center justify-center px-6 py-3 bg-dark-800 text-white rounded-lg border border-dark-700 hover:bg-dark-700 transition-colors"
          >
            <Phone className="w-5 h-5 mr-3" />
            Continuer avec un numéro de téléphone
          </button>
        </div>

        {showPhoneAuth && (
          <div className="mt-6 w-full max-w-sm">
            <div className="bg-dark-800 rounded-lg p-6 border border-dark-700">
              {!verificationId ? (
                <>
                  <input
                    type="tel"
                    placeholder="+33 6 12 34 56 78"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="w-full px-4 py-2 bg-dark-700 border border-dark-600 rounded-lg text-white mb-4"
                  />
                  <div id="recaptcha-container" className="mb-4"></div>
                  <button
                    onClick={async () => {
                      try {
                        const result = await signInWithPhone(phoneNumber);
                        setVerificationId(result);
                      } catch (error: any) {
                        setError(error.message || "Erreur lors de l'envoi du code");
                      }
                    }}
                    className="w-full px-6 py-3 bg-gold-500 text-dark-900 font-semibold rounded-lg hover:bg-gold-600 transition-colors"
                  >
                    Envoyer le code
                  </button>
                </>
              ) : (
                <>
                  <input
                    type="text"
                    placeholder="Code de vérification"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    className="w-full px-4 py-2 bg-dark-700 border border-dark-600 rounded-lg text-white mb-4"
                  />
                  <button
                    onClick={async () => {
                      try {
                        await verifyCode(verificationCode);
                      } catch (error: any) {
                        setError(error.message || "Code invalide");
                      }
                    }}
                    className="w-full px-6 py-3 bg-gold-500 text-dark-900 font-semibold rounded-lg hover:bg-gold-600 transition-colors"
                  >
                    Vérifier le code
                  </button>
                </>
              )}

              {error && (
                <p className="mt-4 text-red-500 text-sm text-center">{error}</p>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gold-400"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Map */}
        <div className="bg-dark-800 rounded-xl shadow-xl p-4 border border-dark-700">
          <h2 className="text-lg font-semibold mb-4 text-gold-300">Mes lieux visités</h2>
          <div className="aspect-square bg-dark-900 rounded-lg">
            <FranceMap places={places} />
          </div>
        </div>

        {/* Stats */}
        <div className="space-y-6">
          {/* Top Rated Places */}
          <div className="bg-dark-800 rounded-xl shadow-xl p-4 border border-dark-700">
            <h2 className="text-lg font-semibold mb-4 flex items-center text-gold-300">
              <Star className="w-5 h-5 mr-2 text-gold-400" />
              Lieux les mieux notés
            </h2>
            {topRatedPlaces.length > 0 ? (
              <div className="space-y-4">
                {topRatedPlaces.map(place => (
                  <div
                    key={place.id}
                    className="flex items-center justify-between p-3 hover:bg-dark-700 rounded-lg cursor-pointer transition-colors"
                    onClick={() => navigate(`/place/${place.id}`)}
                  >
                    <div>
                      <h3 className="font-medium text-gray-200">{place.name}</h3>
                      <p className="text-sm text-gray-400">{place.itemsCount} délices</p>
                    </div>
                    <StarRating rating={place.avgRating || 0} readonly />
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-8">
                <p className="text-gray-400">Notez vos premiers délices pour les voir apparaître ici</p>
              </div>
            )}
          </div>

          {/* Recent Places */}
          <div className="bg-dark-800 rounded-xl shadow-xl p-4 border border-dark-700">
            <h2 className="text-lg font-semibold mb-4 flex items-center text-gold-300">
              <Clock className="w-5 h-5 mr-2 text-gold-400" />
              Derniers lieux visités
            </h2>
            {recentPlaces.length > 0 ? (
              <div className="space-y-3">
                {recentPlaces.map(place => (
                  <div
                    key={place.id}
                    className="flex items-center justify-between p-3 hover:bg-dark-700 rounded-lg cursor-pointer transition-colors"
                    onClick={() => navigate(`/place/${place.id}`)}
                  >
                    <div>
                      <h3 className="font-medium text-gray-200">{place.name}</h3>
                      <p className="text-sm text-gray-400">{place.address}</p>
                    </div>
                    <MapPin className="w-5 h-5 text-gold-400" />
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-8">
                <p className="text-gray-400">Vos derniers lieux visités apparaîtront ici</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Quick Search Button */}
      <div className="fixed bottom-6 right-6">
        <button
          onClick={() => navigate('/search')}
          className="flex items-center px-6 py-3 bg-gold-500 text-dark-900 font-semibold rounded-full shadow-lg hover:bg-gold-600 transition-colors"
        >
          <Navigation className="w-5 h-5 mr-2" />
          Lieux autour de moi
        </button>
      </div>
    </div>
  );
}