import { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { Mail, X, Trash2, Share2 } from 'lucide-react';
import { useAuth } from './AuthProvider';
import { sharePlaceByEmail, getPlaceShares, removeShare } from '../lib/firebase';
import { useAnalytics } from '../hooks/useAnalytics';

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  placeId: string;
  placeName: string;
}

interface Share {
  id: string;
  toEmail: string;
  sharedAt: any;
}

export default function ShareModal({ isOpen, onClose, placeId, placeName }: ShareModalProps) {
  const { user } = useAuth();
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [shares, setShares] = useState<Share[]>([]);
  const [loading, setLoading] = useState(true);
  const analytics = useAnalytics();

  useEffect(() => {
    const loadShares = async () => {
      if (!user || !placeId) return;
      try {
        setLoading(true);
        const placeShares = await getPlaceShares(user.uid, placeId);
        setShares(placeShares);
      } catch (error) {
        console.error('Error loading shares:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      loadShares();
    }
  }, [user, placeId, isOpen]);

  const handleShare = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      setError(null);
      await sharePlaceByEmail(user.uid, email, placeId);
      analytics.trackEvent('share_place', { method: 'email' });
      
      // Refresh shares list
      const updatedShares = await getPlaceShares(user.uid, placeId);
      setShares(updatedShares);
      
      setSuccess(true);
      setEmail('');
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } catch (error: any) {
      setError(error.message || 'Erreur lors du partage');
    }
  };

  const handleRemoveShare = async (shareId: string) => {
    try {
      await removeShare(shareId);
      setShares(shares.filter(share => share.id !== shareId));
      analytics.trackEvent('remove_share');
    } catch (error) {
      console.error('Error removing share:', error);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-10 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

        <div className="relative bg-dark-800 rounded-xl max-w-md w-full mx-4 p-6 border border-dark-700">
          <div className="absolute top-4 right-4">
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-300"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          <Dialog.Title className="text-lg font-medium text-gold-300 mb-4">
            Partager {placeName}
          </Dialog.Title>

          <form onSubmit={handleShare} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Email du destinataire
              </label>
              <div className="relative">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 bg-dark-700 border border-dark-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-gold-500"
                  placeholder="exemple@email.com"
                  required
                />
                <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
              </div>
            </div>

            {error && (
              <p className="text-sm text-red-500">{error}</p>
            )}

            {success && (
              <p className="text-sm text-green-500">Lieu partagé avec succès !</p>
            )}

            <button
              type="submit"
              className="w-full px-4 py-2 text-sm font-medium text-dark-900 bg-gold-500 border border-transparent rounded-md hover:bg-gold-600"
            >
              <Share2 className="w-4 h-4 inline-block mr-2" />
              Partager
            </button>
          </form>

          {/* Current Shares */}
          {shares.length > 0 && (
            <div className="mt-6">
              <h3 className="text-sm font-medium text-gray-300 mb-2">
                Partagé avec
              </h3>
              <div className="space-y-2">
                {shares.map((share) => (
                  <div
                    key={share.id}
                    className="flex items-center justify-between p-2 bg-dark-700 rounded-lg"
                  >
                    <div>
                      <p className="text-sm text-white">{share.toEmail}</p>
                      <p className="text-xs text-gray-400">
                        Partagé le {new Date(share.sharedAt.toDate()).toLocaleDateString()}
                      </p>
                    </div>
                    <button
                      onClick={() => handleRemoveShare(share.id)}
                      className="p-1 text-gray-400 hover:text-red-500 transition-colors"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
}