import { Star } from 'lucide-react';

interface StarRatingProps {
  rating: number;
  onChange?: (rating: number) => void;
  readonly?: boolean;
}

export default function StarRating({ rating, onChange, readonly = false }: StarRatingProps) {
  const handleClick = (index: number, half: boolean) => {
    if (readonly || !onChange) return;
    const newRating = half ? index + 0.5 : index + 1;
    onChange(newRating);
  };

  return (
    <div className="flex gap-1">
      {[...Array(5)].map((_, index) => {
        const filled = rating >= index + 1;
        const halfFilled = rating === index + 0.5;
        
        return (
          <div
            key={index}
            className={`relative ${!readonly ? 'cursor-pointer' : ''}`}
            onClick={readonly ? undefined : () => handleClick(index, false)}
          >
            {/* Left half (for half-star ratings) */}
            <div
              className="absolute left-0 w-1/2 h-full"
              onClick={(e) => {
                if (!readonly) {
                  e.stopPropagation();
                  handleClick(index, true);
                }
              }}
            />
            <Star
              className={`w-6 h-6 ${
                filled
                  ? 'text-gold-400 fill-current'
                  : halfFilled
                  ? 'text-gold-400 [clip-path:polygon(0_0,50%_0,50%_100%,0_100%)] fill-current'
                  : 'text-gray-600'
              }`}
            />
            {halfFilled && (
              <Star
                className="w-6 h-6 absolute top-0 left-0 text-gray-600 [clip-path:polygon(50%_0,100%_0,100%_100%,50%_100%)]"
              />
            )}
          </div>
        );
      })}
    </div>
  );
}