import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin, Star, Calendar } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { getSharedWithMe } from '../lib/firebase';
import StarRating from '../components/StarRating';

export default function Shared() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [sharedPlaces, setSharedPlaces] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadSharedPlaces = async () => {
      if (!user?.email) return;

      try {
        setLoading(true);
        const places = await getSharedWithMe(user.email);
        setSharedPlaces(places);
      } catch (error) {
        console.error('Error loading shared places:', error);
      } finally {
        setLoading(false);
      }
    };

    loadSharedPlaces();
  }, [user]);

  if (loading) {
    return (
      <div className="flex justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gold-400"></div>
      </div>
    );
  }

  if (sharedPlaces.length === 0) {
    return (
      <div className="text-center py-12">
        <h2 className="text-xl font-semibold text-gray-300">
          Aucun lieu partagé avec vous pour le moment
        </h2>
        <p className="text-gray-400 mt-2">
          Les lieux que d'autres utilisateurs partageront avec vous apparaîtront ici
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gold-300">Avis partagés avec vous</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {sharedPlaces.map((place) => (
          <div
            key={`${place.sharedBy}-${place.id}`}
            className="bg-dark-800 rounded-xl shadow-xl overflow-hidden border border-dark-700"
          >
            {place.photoUrl ? (
              <div className="h-48 overflow-hidden">
                <img
                  src={place.photoUrl}
                  alt={place.name}
                  className="w-full h-full object-cover"
                />
              </div>
            ) : (
              <div className="h-48 bg-dark-700 flex items-center justify-center">
                <span className="text-gray-500">Pas de photo</span>
              </div>
            )}
            <div className="p-4">
              <h3 className="font-semibold text-lg text-gold-300">{place.name}</h3>
              <div className="flex items-center gap-2 text-gray-400 mt-1">
                <MapPin className="w-4 h-4 text-gold-400" />
                <p className="text-sm">{place.address}</p>
              </div>
              <div className="mt-2 space-y-2">
                <div className="flex items-center gap-2">
                  <Star className="w-4 h-4 text-gold-400" />
                  <span className="text-sm text-gray-300">
                    {place.items?.length || 0} délices notés
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <Calendar className="w-4 h-4 text-gold-400" />
                  <span className="text-sm text-gray-400">
                    Partagé le {new Date(place.sharedAt.toDate()).toLocaleDateString()}
                  </span>
                </div>
              </div>
              <button
                onClick={() => { 
                  navigate(`/place/${place.id}?sharedByUserId=${place.sharedByUserId}`);
                }}
                className="mt-4 w-full px-4 py-2 bg-gold-500 text-dark-900 font-semibold rounded-lg hover:bg-gold-600 transition-colors"
              >
                Voir les détails
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}