import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  GoogleAuthProvider, 
  signInWithPopup, 
  RecaptchaVerifier, 
  signInWithPhoneNumber,
  User 
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  deleteDoc,
  query,
  where,
  orderBy,
  limit,
  Timestamp,
  DocumentData,
  writeBatch,
  enableIndexedDbPersistence
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

// Initialize Firebase
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: "G-HCZ06JXKQ7"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// Enable offline persistence
enableIndexedDbPersistence(db).catch((err) => {
  console.error('Firebase persistence error:', err);
});

interface PlaceData {
  name: string;
  address: string;
  location: {
    lat: number;
    lng: number;
  };
  phone?: string;
  types?: string[];
  photoUrl?: string;
  avgRating?: number;
  itemsCount?: number;
  lastVisited?: Date;
}

interface ItemData {
  name: string;
  rating: number;
  comment?: string;
  photoUrl?: string;
  category?: string;
}

// Nouvelle interface pour les données d'un lieu personnalisé
interface CustomPlaceData {
  name: string;
  address: string;
  city: string;
  phone?: string;
  photos: string[];  // Changé de photoUrl à photos pour gérer plusieurs images
  location: {
    lat: number;
    lng: number;
  };
}

// Validate and sanitize place data
const sanitizePlaceData = (data: PlaceData) => {
  return {
    name: String(data.name),
    address: String(data.address),
    location: {
      lat: Number(data.location.lat),
      lng: Number(data.location.lng)
    },
    phone: data.phone ? String(data.phone) : null,
    types: Array.isArray(data.types) ? data.types.map(String) : [],
    photoUrl: data.photoUrl ? String(data.photoUrl) : null,
    avgRating: typeof data.avgRating === 'number' ? data.avgRating : null,
    itemsCount: typeof data.itemsCount === 'number' ? data.itemsCount : 0,
    lastVisited: Timestamp.now(),
    updatedAt: Timestamp.now()
  };
};

const sanitizeItemData = (data: ItemData) => {
  return {
    name: String(data.name),
    rating: Number(data.rating),
    comment: data.comment ? String(data.comment) : '',
    photoUrl: data.photoUrl ? String(data.photoUrl) : null,
    category: data.category ? String(data.category) : 'other',
    updatedAt: Timestamp.now(),
    deleted: false
  };
};


// User Places
export const addUserPlace = async (userId: string, placeId: string, placeData: PlaceData, force: boolean = false) => {
  try {
    const placeRef = doc(db, 'users', userId, 'places', placeId);
    const placeDoc = await getDoc(placeRef);
    
    // Only add/update the place if forced or if it already exists
    if (force || placeDoc.exists()) {
      const sanitizedData = sanitizePlaceData(placeData);
      if (!placeDoc.exists()) {
        sanitizedData.createdAt = Timestamp.now();
      }
      await setDoc(placeRef, sanitizedData, { merge: true });
    }
    
    return placeRef;
  } catch (error) {
    console.error('Error adding user place:', error);
    throw error;
  }
};

export const getUserPlaces = async (userId: string) => {
  try {
    const placesRef = collection(db, 'users', userId, 'places');
    const snapshot = await getDocs(placesRef);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error getting places:', error);
    return [];
  }
};

export const getTopRatedPlaces = async (userId: string) => {
  try {
    const placesRef = collection(db, 'users', userId, 'places');
    const placesSnapshot = await getDocs(placesRef);
    const places = placesSnapshot.docs;

    // Get items and calculate ratings for each place
    const placesWithRatings = await Promise.all(
      places.map(async place => {
        const itemsSnapshot = await getDocs(collection(place.ref, 'items'));
        const items = itemsSnapshot.docs
          .map(doc => doc.data())
          .filter(item => !item.deleted);

        let ratingSum = 0;
        let ratingCount = 0;

        items.forEach(item => {
          if (item.rating) {
            ratingSum += item.rating;
            ratingCount++;
          }
        });

        return {
          id: place.id,
          ...place.data(),
          avgRating: ratingCount > 0 ? ratingSum / ratingCount : 0,
          itemsCount: items.length
        };
      })
    );

    // Sort by average rating and return top 5
    return placesWithRatings
      .sort((a, b) => (b.avgRating || 0) - (a.avgRating || 0))
      .slice(0, 5);
  } catch (error) {
    console.error('Error getting top rated places:', error);
    return [];
  }
};

export const getRecentPlaces = async (userId: string) => {
  try {
    const placesRef = collection(db, 'users', userId, 'places');
    const q = query(placesRef, orderBy('lastVisited', 'desc'), limit(5));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error getting recent places:', error);
    return [];
  }
};

export const deletePlace = async (userId: string, placeId: string) => {
  try {
    await deleteDoc(doc(db, 'users', userId, 'places', placeId));
  } catch (error) {
    console.error('Error deleting place:', error);
    throw error;
  }
};

// Items
export const addItemToPlace = async (userId: string, placeId: string, itemData: ItemData) => {
  try {
    const itemsRef = collection(db, 'users', userId, 'places', placeId, 'items');
    const itemRef = doc(itemsRef);
    const sanitizedData = {
      ...sanitizeItemData(itemData),
      id: itemRef.id,
      createdAt: Timestamp.now()
    };
    
    await setDoc(itemRef, sanitizedData);
    return itemRef;
  } catch (error) {
    console.error('Error adding item:', error);
    throw error;
  }
};

export const updateItem = async (userId: string, placeId: string, itemId: string, itemData: ItemData) => {
  try {
    const itemRef = doc(db, 'users', userId, 'places', placeId, 'items', itemId);
    const sanitizedData = sanitizeItemData(itemData);
    await setDoc(itemRef, sanitizedData, { merge: true });
    return itemRef;
  } catch (error) {
    console.error('Error updating item:', error);
    throw error;
  }
};

export const deleteItem = async (userId: string, placeId: string, itemId: string) => {
  try {
    const itemRef = doc(db, 'users', userId, 'places', placeId, 'items', itemId);
    await setDoc(itemRef, {
      deleted: true,
      deletedAt: Timestamp.now()
    }, { merge: true });
  } catch (error) {
    console.error('Error deleting item:', error);
    throw error;
  }
};

export const getPlaceItems = async (userId: string, placeId: string) => {
  try {
    const itemsRef = collection(db, 'users', userId, 'places', placeId, 'items');
    const snapshot = await getDocs(
      query(itemsRef, where('deleted', '==', false))
    );
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error getting items:', error);
    return [];
  }
};


// Photo Upload
// export const uploadItemPhoto = async (userId: string, placeId: string, file: File) => {
//   try {
//     const photoRef = ref(storage, `items/${userId}/${placeId}/${uuidv4()}`);
//     await uploadBytes(photoRef, file);
//     return await getDownloadURL(photoRef);
//   } catch (error) {
//     console.error('Error uploading photo:', error);
//     throw error;
//   }
// };

const uploadPlacePhoto = async (userId: string, placeId: string, file: File): Promise<string> => {
  try {
    const fileExtension = file.name.split('.').pop();
    const fileName = `${uuidv4()}.${fileExtension}`;
    const photoRef = ref(storage, `places/${userId}/${placeId}/${fileName}`);
    await uploadBytes(photoRef, file);
    return await getDownloadURL(photoRef);
  } catch (error) {
    console.error('Error uploading place photo:', error);
    throw error;
  }
};

const deletePlacePhoto = async (userId: string, placeId: string, photoUrl: string) => {
  try {
    const photoRef = ref(storage, photoUrl);
    await deleteObject(photoRef);
  } catch (error) {
    console.error('Error deleting place photo:', error);
    throw error;
  }
};


export const uploadItemPhoto = async (userId: string, placeId: string, file: File) => {
  try {
    const fileExtension = file.name.split('.').pop();
    const fileName = `${uuidv4()}.${fileExtension}`;
    const photoRef = ref(storage, `items/${userId}/${placeId}/${fileName}`);
    await uploadBytes(photoRef, file);
    return await getDownloadURL(photoRef);
  } catch (error) {
    console.error('Error uploading photo:', error);
    throw error;
  }
};


// Sharing
export const sharePlaceByEmail = async (userId: string, toEmail: string, placeId: string) => {
  try {
    // Format cohérent: fromUserId_placeId_toEmail
    const shareId = `${userId}_${placeId}_${toEmail}`;
    const shareRef = doc(db, 'shares', shareId);

    // Récupérer les informations de l'utilisateur depuis Firebase Auth
    const userAuth = auth.currentUser;
    let ownerDisplayName = null;

    if (userAuth) {
      if (userAuth.displayName) {
        // Utiliser le nom d'affichage s'il existe
        ownerDisplayName = userAuth.displayName;
      } else if (userAuth.phoneNumber) {
        // Utiliser le numéro de téléphone comme fallback
        ownerDisplayName = userAuth.phoneNumber;
      } else if (userAuth.email) {
        // Utiliser l'email comme dernier recours
        ownerDisplayName = userAuth.email;
      }
    }

    await setDoc(shareRef, {
      fromUserId: userId,
      toEmail,
      placeId,
      ownerDisplayName,
      sharedAt: Timestamp.now()
    });
  } catch (error) {
    console.error('Error sharing place:', error);
    throw error;
  }
};


export const getPlaceShares = async (userId: string, placeId: string) => {
  try {
    const sharesRef = collection(db, 'shares');
    const snapshot = await getDocs(
      query(sharesRef, 
        where('fromUserId', '==', userId),
        where('placeId', '==', placeId)
      )
    );
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error getting shares:', error);
    return [];
  }
};

export const getSharesByOwner = async (userId: string) => {
  try {
    const sharesRef = collection(db, 'shares');
    const snapshot = await getDocs(query(sharesRef, where('fromUserId', '==', userId)));
    const shares = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    
    const placeShares = new Map<string, any>();
    
    for (const share of shares) {
      if (!placeShares.has(share.placeId)) {
        const placeRef = doc(db, 'users', userId, 'places', share.placeId);
        const placeDoc = await getDoc(placeRef);
        if (placeDoc.exists()) {
          placeShares.set(share.placeId, {
            placeId: share.placeId,
            placeName: placeDoc.data().name,
            shares: []
          });
        }
      }
      
      const placeData = placeShares.get(share.placeId);
      if (placeData) {
        placeData.shares.push({
          id: share.id,
          toEmail: share.toEmail,
          sharedAt: share.sharedAt
        });
      }
    }
    
    return Array.from(placeShares.values());
  } catch (error) {
    console.error('Error getting shares by owner:', error);
    return [];
  }
};

export const getSharedWithMe = async (userEmail: string) => {
  try {
    const sharesRef = collection(db, 'shares');
    const snapshot = await getDocs(
      query(sharesRef, where('toEmail', '==', userEmail))
    );
    
    const sharedPlaces = await Promise.all(
      snapshot.docs.map(async shareDoc => {
        const share = shareDoc.data();
        const fromUserId = share.fromUserId;
        const placeId = share.placeId;
        
        // Récupérer les infos du lieu
        const placeRef = doc(db, 'users', fromUserId, 'places', placeId);
        const placeDoc = await getDoc(placeRef);
        
        if (!placeDoc.exists()) return null;
        
        // Récupérer les délices
        const itemsRef = collection(placeRef, 'items');
        const itemsSnapshot = await getDocs(
          query(itemsRef, where('deleted', '==', false))
        );
        
        return {
          id: placeId,
          ...placeDoc.data(),
          items: itemsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })),
          // sharedBy: fromUserId,
          sharedByUserId: fromUserId,
          sharedBy: share.ownerDisplayName,
          sharedAt: share.sharedAt
        };
      })
    );
    
    return sharedPlaces.filter(Boolean);
  } catch (error) {
    console.error('Error getting shared places:', error);
    throw error;
  }
};



export const removeShare = async (shareId: string) => {
  try {
    await deleteDoc(doc(db, 'shares', shareId));
  } catch (error) {
    console.error('Error removing share:', error);
    throw error;
  }
};

export const removeAllShares = async (userId: string) => {
  try {
    const batch = writeBatch(db);
    const sharesRef = collection(db, 'shares');
    const snapshot = await getDocs(query(sharesRef, where('fromUserId', '==', userId)));
    
    snapshot.docs.forEach(doc => {
      batch.delete(doc.ref);
    });
    
    await batch.commit();
  } catch (error) {
    console.error('Error removing all shares:', error);
    throw error;
  }
};

// User Stats
export const getUserStats = async (userId: string) => {
  try {
    const placesRef = collection(db, 'users', userId, 'places');
    const placesSnapshot = await getDocs(placesRef);
    const places = placesSnapshot.docs;

    let totalItems = 0;
    let totalPhotos = 0;
    let totalRatings = 0;
    let ratingSum = 0;

    // Get items for each place
    const itemsPromises = places.map(place => 
      getDocs(collection(place.ref, 'items'))
    );
    const itemsSnapshots = await Promise.all(itemsPromises);

    itemsSnapshots.forEach(snapshot => {
      const items = snapshot.docs
        .map(doc => doc.data())
        .filter(item => !item.deleted);

      totalItems += items.length;
      totalPhotos += items.filter(item => item.photoUrl).length;
      
      items.forEach(item => {
        if (item.rating) {
          totalRatings++;
          ratingSum += item.rating;
        }
      });
    });

    return {
      totalPlaces: places.length,
      totalItems,
      totalPhotos,
      averageRating: totalRatings > 0 ? (ratingSum / totalRatings).toFixed(1) : 0,
      totalRatings
    };
  } catch (error) {
    console.error('Error getting user stats:', error);
    return null;
  }
};


// Search functionality
export const searchPlacesAndItems = async (
  userId: string,
  searchTerm: string,
  userLocation: { lat: number; lng: number },
  filters: {
    distance?: number;
    minRating?: number;
    type?: string;
  }
) => {
  try {
    const placesRef = collection(db, 'users', userId, 'places');
    const snapshot = await getDocs(placesRef);
    
    const results = await Promise.all(
      snapshot.docs.map(async doc => {
        const place = doc.data();
        const itemsRef = collection(doc.ref, 'items');
        const itemsSnapshot = await getDocs(query(itemsRef, where('deleted', '==', false)));
        const items = itemsSnapshot.docs.map(item => ({
          id: item.id,
          ...item.data()
        }));

        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          new google.maps.LatLng(userLocation.lat, userLocation.lng),
          new google.maps.LatLng(place.location.lat, place.location.lng)
        );

        if (filters.distance && distance > filters.distance) {
          return null;
        }

        if (filters.type && filters.type !== 'Tous' && 
          // Si c'est un lieu custom, on le montre uniquement en mode "Mes lieux visités"
            !(place.types?.includes(filters.type) || (place.types?.includes('custom') && filters.type === 'custom'))) {
          return null;
        }

        const matchingItems = items.filter(item => {
          if (filters.minRating && item.rating < filters.minRating) {
            return false;
          }

          const searchLower = searchTerm.toLowerCase();
          return (
            item.name.toLowerCase().includes(searchLower) ||
            item.comment?.toLowerCase().includes(searchLower)
          );
        });

        const matchesPlace = place.name.toLowerCase().includes(searchTerm.toLowerCase());

        if (matchingItems.length === 0 && !matchesPlace) {
          return null;
        }

        return {
          id: doc.id,
          ...place,
          distance,
          matchingItems: matchingItems.length > 0 ? matchingItems : undefined
        };
      })
    );

    return results
      .filter(Boolean)
      .sort((a, b) => (a?.distance || 0) - (b?.distance || 0));
  } catch (error) {
    console.error('Error searching places and items:', error);
    return [];
  }
};

const createCustomPlace = async (userId: string, placeData: {
  name: string;
  address: string;
  city: string;
  phone?: string;
  photos: string[];
}) => {
  try {
    const customPlaceId = `custom_${uuidv4()}`;
    
    const searchAddress = placeData.address 
      ? `${placeData.address}, ${placeData.city}, France`
      : `${placeData.city}, France`;
    
    const geocoder = new google.maps.Geocoder();
    
    const result = await new Promise<google.maps.GeocoderResult>((resolve, reject) => {
      geocoder.geocode({ 
        address: searchAddress,
        componentRestrictions: { country: 'fr' }
      }, (results, status) => {
        if (status === 'OK' && results?.[0]) {
          resolve(results[0]);
        } else {
          reject(new Error('Impossible de localiser cette adresse'));
        }
      });
    });

    const location = {
      lat: result.geometry.location.lat(),
      lng: result.geometry.location.lng()
    };

    // Construire l'adresse formatée
    const addressComponents = result.address_components || [];
    const streetNumber = addressComponents.find(c => c.types.includes('street_number'))?.long_name || '';
    const route = addressComponents.find(c => c.types.includes('route'))?.long_name || '';
    const locality = addressComponents.find(c => c.types.includes('locality'))?.long_name || '';
    
    const formattedAddress = placeData.address 
      ? `${streetNumber} ${route}, ${locality}`
      : locality;

    await addUserPlace(userId, customPlaceId, {
      name: placeData.name,
      address: formattedAddress,
      location,
      phone: placeData.phone,
      photos: placeData.photos,
      types: ['custom'],
      isCustom: true
    }, true);

    return customPlaceId;
  } catch (error) {
    console.error('Error creating custom place:', error);
    throw error;
  }
};


const updateCustomPlace = async (userId: string, placeId: string, placeData: CustomPlaceData) => {
  try {
    if (!placeId.startsWith('custom_')) {
      throw new Error('Ce lieu ne peut pas être modifié');
    }

    const placeRef = doc(db, 'users', userId, 'places', placeId);
    const placeDoc = await getDoc(placeRef);
    
    if (!placeDoc.exists()) {
      throw new Error('Lieu non trouvé');
    }

    const searchAddress = placeData.address 
      ? `${placeData.address}, ${placeData.city}, France`
      : `${placeData.city}, France`;
    
    const geocoder = new google.maps.Geocoder();
    
    const result = await new Promise<google.maps.GeocoderResult>((resolve, reject) => {
      geocoder.geocode({ 
        address: searchAddress,
        componentRestrictions: { country: 'fr' }
      }, (results, status) => {
        if (status === 'OK' && results?.[0]) {
          resolve(results[0]);
        } else {
          reject(new Error('Impossible de localiser cette adresse'));
        }
      });
    });

    const location = {
      lat: result.geometry.location.lat(),
      lng: result.geometry.location.lng()
    };

    const addressComponents = result.address_components || [];
    const streetNumber = addressComponents.find(c => c.types.includes('street_number'))?.long_name || '';
    const route = addressComponents.find(c => c.types.includes('route'))?.long_name || '';
    const locality = addressComponents.find(c => c.types.includes('locality'))?.long_name || '';
    
    const formattedAddress = placeData.address 
      ? `${streetNumber} ${route}, ${locality}`
      : locality;

    await setDoc(placeRef, {
      name: placeData.name,
      address: formattedAddress,
      city: locality,
      location,
      phone: placeData.phone,
      photos: placeData.photos,
      types: ['custom'],
      isCustom: true,
      updatedAt: Timestamp.now()
    }, { merge: true });

    return {
      ...placeData,
      address: formattedAddress,
      city: locality,
      location
    };
  } catch (error) {
    console.error('Error updating custom place:', error);
    throw error;
  }
};


export const deleteCustomPlace = async (userId: string, placeId: string) => {
  try {
    if (!placeId.startsWith('custom_')) {
      throw new Error('Ce lieu ne peut pas être supprimé');
    }

    // Vérifier s'il y a des délices
    const itemsRef = collection(db, 'users', userId, 'places', placeId, 'items');
    const itemsSnapshot = await getDocs(query(itemsRef, where('deleted', '==', false)));
    
    if (!itemsSnapshot.empty) {
      throw new Error('Impossible de supprimer ce lieu car il contient des délices');
    }

    await deleteDoc(doc(db, 'users', userId, 'places', placeId));
  } catch (error) {
    console.error('Error deleting custom place:', error);
    throw error;
  }
};

// Export auth et db instance
export type { CustomPlaceData };
export { createCustomPlace, updateCustomPlace, uploadPlacePhoto, deletePlacePhoto, auth, db };