import { useState, useEffect } from 'react';

interface Location {
  latitude: number;
  longitude: number;
  accuracy: number;
}

export function useGeolocation() {
  const [location, setLocation] = useState<Location | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!navigator.geolocation) {
      setError('La géolocalisation n\'est pas supportée par votre navigateur');
      return;
    }

    const options = {
      enableHighAccuracy: true, // Utilise le GPS si disponible
      timeout: 5000,
      maximumAge: 0 // Force une position fraîche
    };

    const success = (position: GeolocationPosition) => {
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        accuracy: position.coords.accuracy
      });
    };

    const error = (err: GeolocationPositionError) => {
      let message = 'Impossible de récupérer votre position';
      if (err.code === err.PERMISSION_DENIED) {
        message = 'Veuillez autoriser l\'accès à votre position pour voir les lieux proches';
      } else if (err.code === err.TIMEOUT) {
        message = 'La recherche de votre position a pris trop de temps';
      }
      setError(message);
    };

    // Obtient une position initiale
    navigator.geolocation.getCurrentPosition(success, error, options);

    // Continue de suivre la position
    const watchId = navigator.geolocation.watchPosition(success, error, options);

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  return { location, error };
}