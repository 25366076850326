import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Home, Search, Share2, User, Menu, X } from 'lucide-react';
import { useAuth } from './AuthProvider';

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isActive = (path: string) => location.pathname === path;

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  if (!user) return null;

  return (
    <nav className="bg-[#040807] border-b border-dark-700">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <button 
            onClick={() => handleNavigation('/')}
            className="flex items-center space-x-2 hover:opacity-90 transition-opacity"
          >
            <img src="/official-logo.png" alt="TopDélice" className="w-20 h-20"/>
            <span className="text-xl font-bold text-gold-300">TopDélice</span>
          </button>

          {/* Menu Desktop */}
          <div className="hidden md:flex items-center space-x-8">
            <button
              onClick={() => handleNavigation('/')}
              className={`flex items-center text-sm transition-colors ${
                isActive('/') 
                  ? 'text-gold-300 font-bold border-b-2 border-gold-400' 
                  : 'text-gray-400 hover:text-gray-300'
              }`}
            >
              <Home className="w-4 h-4 mr-2" />
              Accueil
            </button>

            <button
              onClick={() => handleNavigation('/search')}
              className={`flex items-center text-sm transition-colors ${
                isActive('/search')
                  ? 'text-gold-300 font-bold border-b-2 border-gold-400'
                  : 'text-gray-400 hover:text-gray-300'
              }`}
            >
              <Search className="w-4 h-4 mr-2" />
              Rechercher
            </button>

            <button
              onClick={() => handleNavigation('/shared')}
              className={`flex items-center text-sm transition-colors ${
                isActive('/shared')
                  ? 'text-gold-300 font-bold border-b-2 border-gold-400'
                  : 'text-gray-400 hover:text-gray-300'
              }`}
            >
              <Share2 className="w-4 h-4 mr-2" />
              Avis partagés
            </button>

            <button
              onClick={() => handleNavigation('/profile')}
              className={`flex items-center text-sm transition-colors ${
                isActive('/profile')
                  ? 'text-gold-300 font-bold border-b-2 border-gold-400'
                  : 'text-gray-400 hover:text-gray-300'
              }`}
            >
              <User className="w-4 h-4 mr-2" />
              Profil
            </button>
          </div>

          {/* Menu Mobile Button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2 text-gray-400 hover:text-gray-300"
          >
            {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
          </button>
        </div>

        {/* Menu Mobile */}
        <div
          className={`
            md:hidden 
            fixed inset-0 z-50 
            bg-dark-900/95
            transform transition-transform duration-200 ease-in-out
            ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'}
          `}
        >
          <div className="flex flex-col items-center space-y-8 pt-24">
            <button
              onClick={() => handleNavigation('/')}
              className={`flex items-center text-lg transition-colors ${
                isActive('/') 
                  ? 'text-gold-300 font-bold' 
                  : 'text-gray-400'
              }`}
            >
              <Home className="w-5 h-5 mr-3" />
              Accueil
            </button>

            <button
              onClick={() => handleNavigation('/search')}
              className={`flex items-center text-lg transition-colors ${
                isActive('/search')
                  ? 'text-gold-300 font-bold'
                  : 'text-gray-400'
              }`}
            >
              <Search className="w-5 h-5 mr-3" />
              Rechercher
            </button>

            <button
              onClick={() => handleNavigation('/shared')}
              className={`flex items-center text-lg transition-colors ${
                isActive('/shared')
                  ? 'text-gold-300 font-bold'
                  : 'text-gray-400'
              }`}
            >
              <Share2 className="w-5 h-5 mr-3" />
              Avis partagés
            </button>

            <button
              onClick={() => handleNavigation('/profile')}
              className={`flex items-center text-lg transition-colors ${
                isActive('/profile')
                  ? 'text-gold-300 font-bold'
                  : 'text-gray-400'
              }`}
            >
              <User className="w-5 h-5 mr-3" />
              Profil
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}
