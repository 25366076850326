import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  MapPin, Search as SearchIcon, Filter,
  Utensils, Coffee, Beer, Cake, ShoppingBag, 
  Store, Building2, Star, Navigation,
  Plus, Phone, Building, Trash2
} from 'lucide-react';
import { useGoogleMaps } from '../hooks/useGoogleMaps';
import StarRating from '../components/StarRating';
import { useAuth } from '../components/AuthProvider';
import { searchPlacesAndItems, createCustomPlace, uploadItemPhoto, uploadPlacePhoto, updateCustomPlace } from '../lib/firebase';
import { useAnalytics } from '../hooks/useAnalytics';
import { Dialog } from '@headlessui/react';
import { v4 as uuidv4 } from 'uuid';

interface NewPlace {
  name: string;
  address: string;
  city: string;
  phone: string;
  photos: File[]; // Changé de string[] à File[]
}


const PLACE_TYPES = [
  { id: 'Tous', label: 'Tous les types', icon: Store },
  { id: 'restaurant', label: 'Restaurant', icon: Utensils },
  { id: 'cafe', label: 'Café', icon: Coffee },
  { id: 'bar', label: 'Bar', icon: Beer },
  { id: 'bakery', label: 'Boulangerie', icon: Cake },
  { id: 'meal_delivery', label: 'Livraison', icon: ShoppingBag },
  { id: 'meal_takeaway', label: 'À emporter', icon: ShoppingBag },
  { id: 'food', label: 'Alimentation', icon: Store },
  { id: 'supermarket', label: 'Supermarché', icon: Building2 }
] as const;

const DISTANCE_OPTIONS = [
  { value: 500, label: '500m' },
  { value: 1000, label: '1km' },
  { value: 2000, label: '2km' },
  { value: 5000, label: '5km' },
  { value: 10000, label: '10km' },
  { value: 20000, label: '20km' },
  { value: 50000, label: '50km' },
  { value: 100000, label: '100km' },
  { value: 200000, label: '200km' }
] as const;

const FilterChip = ({ 
  icon: Icon, 
  label, 
  isActive, 
  onClick 
}: { 
  icon: any;
  label: string;
  isActive: boolean;
  onClick: () => void;
}) => (
  <button
    onClick={onClick}
    className={`
      flex items-center gap-2 px-3 py-2 
      rounded-full text-sm whitespace-nowrap transition-all
      hover:bg-dark-700
      ${isActive ? 'bg-gold-500 text-dark-900' : 'bg-dark-800 text-gray-300'}
    `}
  >
    <Icon className="w-4 h-4" />
    <span>{label}</span>
  </button>
);

export default function Search() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { searchNearbyPlaces, location } = useGoogleMaps();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState<string>('Tous');
  const [selectedDistance, setSelectedDistance] = useState(5000);
  const [minRating, setMinRating] = useState(0);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searching, setSearching] = useState(true);
  const [searchMode, setSearchMode] = useState<'all' | 'visited'>('all');
  const [showFilters, setShowFilters] = useState(false);

  const [showAddPlaceModal, setShowAddPlaceModal] = useState(false);
  const [newPlace, setNewPlace] = useState<NewPlace>({
    name: '',
    address: '',
    city: '',
    phone: '',
    photos: []
  });
  
  const [addingPlace, setAddingPlace] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  const searchTimeoutRef = useRef<NodeJS.Timeout>();
  const isInitialSearchRef = useRef(true);
  const searchInProgressRef = useRef(false);
  const resultsCache = useRef<Map<string, any[]>>(new Map());
  const analytics = useAnalytics();

  const [cityAutocomplete, setCityAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const addressInputRef = useRef<HTMLInputElement>(null);
  const cityInputRef = useRef<HTMLInputElement>(null);

  // Créer une clé de cache unique pour les paramètres de recherche actuels
  const searchKey = useMemo(() => {
    return JSON.stringify({
      term: searchTerm,
      type: selectedType,
      distance: selectedDistance,
      rating: minRating,
      mode: searchMode,
      lat: location?.latitude.toFixed(3),
      lng: location?.longitude.toFixed(3)
    });
  }, [searchTerm, selectedType, selectedDistance, minRating, searchMode, location]);

  const handleCreatePlace = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;
  
    try {
      setAddingPlace(true);
      setError(null);
  
      // Créer le lieu d'abord pour avoir l'ID réel
      const placeId = await createCustomPlace(user.uid, {
        name: newPlace.name,
        address: newPlace.address,
        city: newPlace.city,
        phone: newPlace.phone,
        photos: [] // On commence avec un tableau vide
      });
  
      // Si on a des photos, les uploader avec l'ID réel du lieu
      if (newPlace.photos.length > 0) {
        const uploadPromises = newPlace.photos.map(file => 
          uploadPlacePhoto(user.uid, placeId, file)
        );
  
        // Attendre que toutes les photos soient uploadées
        const finalPhotoUrls = await Promise.all(uploadPromises);
  
        // Mettre à jour le lieu avec les nouvelles URLs des photos
        await updateCustomPlace(user.uid, placeId, {
          name: newPlace.name,
          address: newPlace.address,
          city: newPlace.city,
          phone: newPlace.phone,
          photos: finalPhotoUrls,
          location: {
            lat: 0,
            lng: 0
          }
        });
      }
  
      // Rediriger vers la page du lieu
      navigate(`/place/${placeId}`);
    } catch (error: any) {
      setError(error.message || "Erreur lors de la création du lieu");
    } finally {
      setAddingPlace(false);
    }
  };
  
  const performSearch = useCallback(async () => {
    if (!user || !location || searchInProgressRef.current) return;

    try {
      // Vérifier le cache
      const cachedResults = resultsCache.current.get(searchKey);
      if (cachedResults) {
        setSearchResults(cachedResults);
        setSearching(false);
        return;
      }

      searchInProgressRef.current = true;
      setSearching(true);

      // Track search event if it's not the initial load
      if (!isInitialSearchRef.current) {
        analytics.trackSearch();
      }

      let results: any[] = [];

      // Recherche dans Firestore
      const firestoreResults = await searchPlacesAndItems(
        user.uid,
        searchTerm,
        { lat: location.latitude, lng: location.longitude },
        {
          distance: selectedDistance,
          minRating,
          type: selectedType !== 'Tous' ? selectedType : undefined
        }
      );

      // Filtrer les résultats Firestore par note minimum
      const filteredFirestoreResults = firestoreResults.filter(place => {
        if (minRating === 0) return true;
        return place.matchingItems?.some((item: any) => item.rating >= minRating);
      });

      // Si on n'est pas en mode "mes lieux visités", ajouter les résultats Google Maps
      if (searchMode === 'all') {
        const googlePlaces = await searchNearbyPlaces(
          selectedType !== 'Tous' ? selectedType : undefined,
          selectedDistance,
          searchTerm
        );

        const googleResults = googlePlaces.map(place => ({
          id: place.place_id,
          name: place.name,
          address: place.vicinity || place.formatted_address,
          location: {
            lat: place.geometry?.location.lat(),
            lng: place.geometry?.location.lng()
          },
          types: place.types,
          isGooglePlace: true,
          distance: google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(location.latitude, location.longitude),
            place.geometry!.location
          )
        }));

        // Fusionner les résultats en évitant les doublons
        results = [
          ...filteredFirestoreResults,
          ...googleResults.filter(gPlace => 
            !filteredFirestoreResults.some(fPlace => fPlace.id === gPlace.id)
          )
        ];
      } else {
        results = filteredFirestoreResults;
      }

      // Filtrer par distance et trier
      results = results
        .filter(result => result.distance <= selectedDistance)
        .sort((a, b) => {
          // Priorité aux résultats avec des délices correspondants
          if (searchTerm) {
            if (a.matchingItems && !b.matchingItems) return -1;
            if (!a.matchingItems && b.matchingItems) return 1;
          }
          // Puis par distance
          return (a.distance || 0) - (b.distance || 0);
        });

      // Mettre en cache les résultats
      resultsCache.current.set(searchKey, results);
      setSearchResults(results);
    } catch (error) {
      console.error('Error searching:', error);
      setSearchResults([]);
    } finally {
      setSearching(false);
      searchInProgressRef.current = false;
    }
  }, [user, location, searchKey, searchTerm, selectedType, selectedDistance, minRating, searchMode, searchNearbyPlaces, analytics]);

  // Effet pour la recherche initiale uniquement
  useEffect(() => {
    if (location && user && isInitialSearchRef.current) {
      performSearch();
      isInitialSearchRef.current = false;
    }
  }, [location, user, performSearch]);

  // Effet pour les changements de filtres et la recherche
  useEffect(() => {
    if (!location || !user || isInitialSearchRef.current) return;

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    const delay = searchTerm ? 300 : 0;
    searchTimeoutRef.current = setTimeout(performSearch, delay);

    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, [location, user, searchKey, performSearch]);

  useEffect(() => {
    if (!cityInputRef.current) return;
  
    const cityAuto = new google.maps.places.Autocomplete(cityInputRef.current, {
      types: ['(cities)'],
      componentRestrictions: { country: 'fr' },
      fields: ['address_components']
    });
    
    cityAuto.addListener('place_changed', () => {
      const place = cityAuto.getPlace();
      if (!place.address_components) return;
      
      // Extraire uniquement le nom de la ville
      const cityComponent = place.address_components.find(
        component => component.types.includes('locality')
      );
      
      if (cityComponent) {
        setNewPlace(prev => ({
          ...prev,
          city: cityComponent.long_name
        }));
        
        // Reset l'input pour n'afficher que le nom de la ville
        cityInputRef.current!.value = cityComponent.long_name;
      }
    });
  
    // Empêcher la soumission du formulaire sur Enter
    cityInputRef.current.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    });
  
    setCityAutocomplete(cityAuto);
  
    return () => {
      if (cityAuto) {
        google.maps.event.clearInstanceListeners(cityAuto);
      }
    };
  }, [cityInputRef.current]);
  
  
  

  if (!location) {
    return (
      <div className="text-center py-12">
        <MapPin className="w-12 h-12 text-gold-400 mx-auto mb-4" />
        <p className="text-gray-300">
          Veuillez autoriser l'accès à votre position pour voir les lieux autour de vous
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="sticky top-0 z-10 bg-dark-900 pb-4 space-y-4">
        {/* Barre de recherche */}
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Rechercher un lieu, un délice..."
            className="w-full px-4 py-3 pl-10 bg-dark-800 border border-dark-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-gold-500"
          />
          <SearchIcon className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
        </div>

        {/* Filtres horizontaux scrollables */}
        <div className="flex flex-col gap-4">
          {/* Mode de recherche */}
          <div className="flex gap-2 overflow-x-auto pb-2 hide-scrollbar">
            <FilterChip
              icon={Navigation}
              label="Tout Google Maps"
              isActive={searchMode === 'all'}
              onClick={() => setSearchMode('all')}
            />
            <FilterChip
              icon={Star}
              label="Mes lieux visités"
              isActive={searchMode === 'visited'}
              onClick={() => setSearchMode('visited')}
            />
          </div>

          {/* Types de lieux */}
          <div className="flex gap-2 overflow-x-auto pb-2 hide-scrollbar">
            {PLACE_TYPES.map(type => (
              <FilterChip
                key={type.id}
                icon={type.icon}
                label={type.label}
                isActive={selectedType === type.id}
                onClick={() => setSelectedType(type.id)}
              />
            ))}
          </div>
          
           {/* Bouton pour ouvrir les filtres avancés */}
          <div className="flex justify-end">
            <FilterChip
              icon={Filter}
              label="Plus de filtres"
              isActive={false}
              onClick={() => setShowFilters(true)}
            />
            <div className="flex ml-4"></div>
            <FilterChip
              icon={Plus}
              label="Ajouter un lieu"
              isActive={false}
              onClick={() => setShowAddPlaceModal(true)}
            />
          </div>
        </div>
      </div>

      {/* Liste des résultats */}
      <div className="space-y-4">
        {searching ? (
          <div className="flex justify-center py-12">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gold-400"></div>
          </div>
        ) : searchResults.length > 0 ? (
          searchResults.map((result) => (
            <div 
              key={result.id} 
              className="bg-dark-800 rounded-xl shadow-xl overflow-hidden border border-dark-700 hover:border-dark-600 transition-colors cursor-pointer"
              onClick={() => navigate(`/place/${result.id}`)}
            >
              <div className="p-4">
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-semibold text-lg text-gold-300">{result.name}</h3>
                    <div className="flex items-center gap-2 text-gray-400 mt-1">
                      <MapPin className="w-4 h-4 text-gold-400" />
                      <p className="text-sm">{result.address}</p>
                      {result.distance && (
                        <span className="text-sm">
                          ({(result.distance / 1000).toFixed(1)} km)
                        </span>
                      )}
                    </div>
                    {result.matchingItems && (
                      <div className="mt-2">
                        <p className="text-sm text-gold-400">
                          {result.matchingItems.length} délice{result.matchingItems.length > 1 ? 's' : ''} trouvé{result.matchingItems.length > 1 ? 's' : ''}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-8 text-gray-400">
            Aucun résultat trouvé
          </div>
        )}
      </div>

      {/* Modal des filtres avancés */}
      <Dialog
        open={showFilters}
        onClose={() => setShowFilters(false)}
        className="fixed inset-0 z-20 overflow-y-auto"
      >
        <div className="flex items-end sm:items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          
          <div className="relative bg-dark-800 w-full sm:max-w-lg mx-auto rounded-t-xl sm:rounded-xl p-6">
            <h3 className="text-lg font-medium text-gold-300 mb-4">Filtres</h3>
            
            {/* Distance */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Distance maximum
              </label>
              <div className="grid grid-cols-3 gap-2">
                {DISTANCE_OPTIONS.map(option => (
                  <button
                    key={option.value}
                    onClick={() => setSelectedDistance(option.value)}
                    className={`
                      px-3 py-2 rounded-lg text-sm
                      ${selectedDistance === option.value
                        ? 'bg-gold-500 text-dark-900'
                        : 'bg-dark-700 text-gray-300'
                      }
                    `}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
            
            {/* Note minimum */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Note minimum
              </label>
              <StarRating rating={minRating} onChange={setMinRating} />
            </div>
            
            <div className="mt-6 flex justify-end">
              <button
                onClick={() => setShowFilters(false)}
                className="px-4 py-2 bg-gold-500 text-dark-900 rounded-lg"
              >
                Appliquer
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Modale ajout d'un lieu */}
      <Dialog
        open={showAddPlaceModal}
        onClose={() => setShowAddPlaceModal(false)}
        className="fixed inset-0 z-20 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          
          <div className="relative bg-dark-800 w-full sm:max-w-lg mx-auto rounded-xl p-6">
            <h3 className="text-lg font-medium text-gold-300 mb-4">Ajouter un lieu</h3>
            
            <form onSubmit={handleCreatePlace} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Nom *
                </label>
                <input
                  type="text"
                  required
                  value={newPlace.name}
                  onChange={e => setNewPlace(prev => ({ ...prev, name: e.target.value }))}
                  className="w-full px-4 py-2 bg-dark-700 border border-dark-600 rounded-lg text-white"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Adresse
                </label>
                <input
                  type="text"
                  ref={addressInputRef}
                  value={newPlace.address}
                  onChange={e => setNewPlace(prev => ({ ...prev, address: e.target.value }))}
                  className="w-full px-4 py-2 bg-dark-700 border border-dark-600 rounded-lg text-white"
                  placeholder="Saisissez une adresse..."
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Ville *
                </label>
                <input
                  type="text"
                  required
                  ref={cityInputRef}
                  value={newPlace.city}
                  onChange={e => setNewPlace(prev => ({ ...prev, city: e.target.value }))}
                  className="w-full px-4 py-2 bg-dark-700 border border-dark-600 rounded-lg text-white"
                  placeholder="Saisissez une ville..."
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Téléphone
                </label>
                <input
                  type="tel"
                  value={newPlace.phone}
                  onChange={e => setNewPlace(prev => ({ ...prev, phone: e.target.value }))}
                  className="w-full px-4 py-2 bg-dark-700 border border-dark-600 rounded-lg text-white"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Photos
                </label>
                
                {/* Zone de téléchargement */}
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dark-600 border-dashed rounded-md hover:border-dark-500">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="flex text-sm text-gray-400">
                      <label
                        htmlFor="place-photo-upload"
                        className="relative cursor-pointer bg-dark-800 rounded-md font-medium text-gold-400 hover:text-gold-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-gold-500"
                      >
                        <span>Télécharger un fichier</span>
                        <input
                          id="place-photo-upload"
                          name="place-photo-upload"
                          type="file"
                          accept="image/*"
                          className="sr-only"
                          onChange={async (e) => {
                            const file = e.target.files?.[0];
                            if (file && user) {
                              try {
                                setNewPlace(prev => ({
                                  ...prev,
                                  photos: [...prev.photos, file] // Stocke le File directement
                                }));
                              } catch (error) {
                                console.error('Error handling photo:', error);
                                setError('Erreur lors du traitement de la photo');
                              }
                            }
                          }}
                        />
                      </label>
                    </div>
                    <p className="text-xs text-gray-500">
                      PNG, JPG jusqu'à 10MB
                    </p>
                  </div>
                </div>

                {/* Grille des photos existantes */}
                {newPlace.photos.length > 0 && (
                  <div className="mt-4 grid grid-cols-2 gap-4">
                    {newPlace.photos.map((file, index) => (
                      <div key={index} className="relative group">
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Photo ${index + 1}`}
                          className="h-24 w-full object-cover rounded-lg"
                        />
                        <button
                          type="button"
                          onClick={() => {
                            setNewPlace(prev => ({
                              ...prev,
                              photos: prev.photos.filter((_, i) => i !== index)
                            }));
                          }}
                          className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <Trash2 className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>


              {error && (
                <p className="text-sm text-red-500">{error}</p>
              )}

              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  onClick={() => setShowAddPlaceModal(false)}
                  className="px-4 py-2 text-sm text-gray-300 hover:text-white"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  disabled={addingPlace}
                  className="px-4 py-2 bg-gold-500 text-dark-900 rounded-lg disabled:opacity-50"
                >
                  {addingPlace ? 'Création...' : 'Créer le lieu'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </div>
  );
}